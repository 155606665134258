// 对外暴露配置路由（常量路由）
export const constanRouter = [
    {
        //路由路径
        path: "/login",
        // @ts-ignore
        //路由组件
        component: () => import("@/views/login/index.vue"),
        name: "login", //命名路由
        meta: {
            title: "登录", //菜单标题
            hidden: true, //代表路由标题在菜单中是否隐藏， true：隐藏，false：不隐藏
        },
    },
    {
        path: "/",
        component: () => import("@/layout/index.vue"),
        name: "layout", //命名路由
        redirect: "/home", //重定向
        children: [
            {
                path: '/home',
                component: () => import('@/views/home/index.vue'),
                meta: {
                    title: "首页",
                    icon: 'el-icon-s-home'  //菜单左侧的图标
                },
            },
            {
                path: '/admin',
                component: () =>
                    import("@/views/admin/index.vue"),
                meta: {
                    title: '管理员列表',
                }
            },
            {
                path: '/power',
                component: () =>
                    import("@/views/power/index.vue"),
                meta: {
                    title: '权限列表',
                }
            },
        ],
    },
    {
        path: "/plat",
        component: () => import("@/layout/index.vue"),
        name: "plat", //命名路由
        redirect: '/plat',
        meta: {
            title: "平台管理",
            icon: 'el-icon-takeaway-box'
        },
        children: [
            {
                path: '/plat',
                component: () => import('@/views/plat/index.vue'),
                meta: {
                    title: "平台列表",
                    icon: 'el-icon-takeaway-box'  //菜单左侧的图标
                },
            },
            {
                path: '/spreadPower',
                component: () => import('@/views/spread-power/index.vue'),
                meta: {
                    title: "平台菜单",
                    icon: 'el-icon-takeaway-box'  //菜单左侧的图标
                },
            },
            {
                path: '/template',
                component: () => import('@/views/template/index.vue'),
                meta: {
                    title: "模板列表",
                    icon: 'el-icon-printer'  //菜单左侧的图标
                },
            },
        ],
    },
    {
        path: "/behalf",
        component: () => import("@/layout/index.vue"),
        name: "behalf", //命名路由
        redirect: '/behalf',
        meta: {
            title: "代发单管理",
            icon: 'el-icon-takeaway-box'
        },
        children: [
            {
                path: '/behalf',
                component: () =>
                    import("@/views/behalf/index.vue"),
                meta: {
                    title: '代发单列表',
                }
            },
            {
                path: '/behalfConfig',
                component: () =>
                    import("@/views/system-config/order/index.vue"),
                meta: {
                    title: '代发单配置',
                }
            },
        ],
    },
    {
        path: "/product",
        component: () => import("@/layout/index.vue"),
        name: "product", //命名路由
        redirect: '/product',
        meta: {
            title: "商品管理",
            icon: 'el-icon-shopping-cart-full'
        },
        children: [
            {
                path: '/product',
                component: () => import('@/views/product/index.vue'),
                meta: {
                    title: "商品列表",
                    icon: 'el-icon-shopping-cart-2'  //菜单左侧的图标
                },
            },
            {
                path: '/productCategory',
                component: () => import('@/views/product-category/index.vue'),
                meta: {
                    title: "商品分类",
                    icon: 'el-icon-present'  //菜单左侧的图标
                },
            },
            {
                path: '/productBrand',
                component: () => import('@/views/product-brand/index.vue'),
                meta: {
                    title: "品牌列表",
                    icon: 'el-icon-medal'  //菜单左侧的图标
                },
            },
            {
                path: '/productBrandCategory',
                component: () => import('@/views/product-brand-category/index.vue'),
                meta: {
                    title: "品牌类目",
                    icon: 'el-icon-present'  //菜单左侧的图标
                },
            },
            {
                path: '/productBrandType',
                component: () => import('@/views/product-brand-type/index.vue'),
                meta: {
                    title: "品牌类型",
                    icon: 'el-icon-dish'  //菜单左侧的图标
                },
            },
            {
                path: '/productEfficacy',
                component: () => import('@/views/product-efficacy/index.vue'),
                meta: {
                    title: "商品功效",
                    icon: 'el-icon-dish'  //菜单左侧的图标
                },
            },
        ],
    },
    {
        path: "/medical",
        component: () => import("@/layout/index.vue"),
        name: "medical", //命名路由
        redirect: '/medical',
        meta: {
            title: "医疗器械",
            icon: 'el-icon-shopping-cart-full'
        },
        children: [
            {
                path: '/medicalProduct',
                component: () => import('@/views/medical-product/index.vue'),
                meta: {
                    title: "商品列表",
                    icon: 'el-icon-shopping-cart-2'  //菜单左侧的图标
                },
            },
            {
                path: '/medicalProductCategory',
                component: () => import('@/views/medical-product-category/index.vue'),
                meta: {
                    title: "商品分类",
                    icon: 'el-icon-present'  //菜单左侧的图标
                },
            },
            {
                path: '/medicalProductBrand',
                component: () => import('@/views/medical-product-brand/index.vue'),
                meta: {
                    title: "品牌列表",
                    icon: 'el-icon-medal'  //菜单左侧的图标
                },
            },
        ],
    },
    {
        path: "/finance",
        component: () => import("@/layout/index.vue"),
        name: "finance", //命名路由
        redirect: '/finance',
        meta: {
            title: "财务管理",
            icon: 'el-icon-s-tools'
        },
        children: [
            {
                path: '/fund',
                component: () => import('@/views/fund/index.vue'),
                meta: {
                    title: "账户管理",
                    icon: 'el-icon-truck'  //菜单左侧的图标
                },
            },
            {
                path: '/delivery',
                component: () => import('@/views/delivery/index.vue'),
                meta: {
                    title: "配送管理",
                    icon: 'el-icon-truck'  //菜单左侧的图标
                },
            },
        ],
    },
    {
        path: "/system",
        component: () => import("@/layout/index.vue"),
        name: "system", //命名路由
        redirect: '/delivery',
        meta: {
            title: "系统管理",
            icon: 'el-icon-s-tools'
        },
        children: [
            {
                path: '/attachment',
                component: () => import('@/views/attachment/index.vue'),
                meta: {
                    title: "附件管理",
                    icon: 'el-icon-folder'  //菜单左侧的图标
                },
            },
        ],
    },
    {
        //路由路径
        path: "/404",
        //路由组件
        component: () => import("@/views/404/index.vue"),
        name: "404", //命名路由
        meta: {
            title: "404",
            hidden: true, //代表路由标题在菜单中是否隐藏， true：隐藏，false：不隐藏
        },
    },
    {
        //路由路径
        path: "/:pathMatch(.*)*",
        //重定向到404路由
        redirect: '/404',
        name: 'Any',
        meta: {
            title: "任意路由",
            hidden: true, //代表路由标题在菜单中是否隐藏， true：隐藏，false：不隐藏
        },
    },
]