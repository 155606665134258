//统一管理咱们项目附件管理相关的接口
import request from "@/utils/request"
//项目附件管理相关的请求地址
const API = {
  ATTACHMENT_LIST_URL: "/super/attachment/list",
  ATTACHMENT_DETAIL_URL: "/attachment/detail",
  ATTACHMENT_ADD_URL: "/attachment/add",
  ATTACHMENT_EDIT_URL: "/attachment/edit",
  ATTACHMENT_DEL_URL: "/attachment/del",
}

//获取附件管理列表
export const list = (params) =>
  request.get(API.ATTACHMENT_LIST_URL, { params })

//获取附件管理详情
export const detail = (params) =>
  request.get(API.ATTACHMENT_DETAIL_URL, { params })

//附件管理添加图片
export const upload = (data) =>
  request.post(API.ATTACHMENT_ADD_URL, data)

//修改附件管理
export const update = (data) =>
  request.post(API.ATTACHMENT_EDIT_URL, data)

//删除附件管理
export const destroy = (data) =>
  request.post(API.ATTACHMENT_DEL_URL, data)
