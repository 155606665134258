<template>
    <div>
        <el-dialog title="下载中心" :visible.sync="dialogTableVisible" width="60%" top="2vh">
            <el-button
                type="info"
                size="mini"
                icon="el-icon-refresh"
                @click="getList"
            >
            </el-button>
            <el-table
                :data="tableList"
                v-loading="downloadLoading"
                tooltip-effect="dark"
                highlight-current-row
            >
                <el-table-column property="id" label="ID" width="110"></el-table-column>
                <el-table-column
                    property="name"
                    label="名称"
                    width="240"
                    align="center"
                ></el-table-column>
                <el-table-column
                    property="status"
                    label="状态"
                    min-width="120"
                    align="center"
                >
                    <template slot-scope="scope">
                        <el-tag
                            :type="
                scope.row.status == 1
                  ? 'info'
                  : scope.row.status == 2
                  ? 'success'
                  : 'danger'
              "
                            size="mini"
                        >
                            {{
                                scope.row.status == 1
                                    ? "导出中"
                                    : scope.row.status == 2
                                        ? "导出完成"
                                        : "导出失败"
                            }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    property="create_time"
                    label="导出时间"
                ></el-table-column>
                <el-table-column label="操作" width="140" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text" @click="downchange(scope.row)" v-if="scope.row.status === 2"
                        >下载
                        </el-button
                        >
                        <el-button
                            style="color: red" size="mini" type="text" @click="del(scope.row)"
                            v-if="scope.row.status !== 1"
                        >删除
                        </el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <pagination
                :page="params.page"
                :page_size="params.page_size"
                :total="total"
                @currentChange="currentChange"
                @sizeChange="sizeChange"
            ></pagination>
        </el-dialog>
    </div>
</template>

<script>
import {exportList, destroy} from "@/api/file"

export default {
    data() {
        return {
            dialogTableVisible: false,
            downloadLoading: true,
            tableList: [],
            params: {
                page: 1,
                page_size: 10,
            },
            total: 0,
        }
    },
    methods: {
        open() {
            this.dialogTableVisible = true
            this.tableList = []
            this.getList()
        },
        // 获取下载中心列表
        async getList() {
            try {
                const {data} = await exportList(this.params)
                this.downloadLoading = false
                this.tableList = data.data
                this.total = data.total
            } catch (ex) {
                this.loading = false
                console.log(ex)
            }
        },
        // 分页
        sizeChange(e) {
            this.params.page_size = e
            this.getList()
        },
        currentChange(e) {
            this.params.page = e
            this.getList()
        },
        // 下载
        downchange(e) {
            if (e.download_url) {
                window.open(e.download_url)
            } else {
                this.$msg.successMsg("导出失败，请稍后重试")
            }
        },
        // 删除
        del(e) {
            const that = this
            that.$msg.massageConfirm("是否删除当前项？", async function () {
                try {
                    const data = await destroy({ids: e.id})
                    that.$msg.successMsg(data.msg)
                    that.getList()
                } catch (ex) {
                    console.log(ex)
                }
            })
        },
    },
}
</script>
