// 封装本地存储数据与读取数据的方法

// 存
export const SET_TOKEN = (token) => {
  localStorage.setItem("TOKEN", token)
}

// 取
export const GET_TOKEN = () => {
  return localStorage.getItem("TOKEN")
}

// 删
export const REMOVE_TOKEN = () => {
  localStorage.removeItem("TOKEN")
}
