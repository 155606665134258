// 路由鉴权
import router from '@/router'
import nprogress from 'nprogress'
// 引入进度条的样式
import 'nprogress/nprogress.css'
// 把进度条右边的小圆圈隐藏
nprogress.configure({ showSpinner: false })
// 获取用户仓库
import store from './store'

// 全局前置守卫
router.beforeEach(async (to, from, next) => {
  nprogress.start()
  const token = store.state.user.token
  let username = store.state.user.username
  if (token) {
    // 用户登录
    if (to.path == "/login") {
      // 登录成功，访问login，不能访问，指向首页
      next({ path: "/" })
    } else {
      // 登录成功访问其它的路由（抛出登录）
      if (username) {
        // 有用户信息
        next()
      } else {
        // 如果没有用户信息，在守卫这里发请求获取用户信息在放行
        try {
          // await store.dispatch('user/userInfo')
          next()
        } catch (error) {
          // token过期，用户手动修改了本地存储的token
          // 退出登录->用户相关的数据清空
          store.dispatch('user/userLogout')
          next({ path: "/login", query: { redirect: to.path } })
        }
      }
    }
  } else {
    // 用户未登录
    if (to.path == "/login") {
      next()
    } else {
      next({ path: "/login", query: { redirect: to.path } })
    }
  }
})

// 全局后置守卫
router.afterEach((to, from) => {
  nprogress.done()
})