<template>
  <el-pagination
    v-model:current-page="page"
    v-model:page-size="page_size"
    :page-sizes="pageSizes"
    :background="true"
    layout="prev, pager, next, jumper,->,sizes,total"
    :total="total"
    @current-change="$emit('currentChange', $event)"
    @size-change="$emit('sizeChange', $event)"
  />
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    page_size: {
      type: Number,
      default: 10,
    },
    pageSizes: {
      type: Array,
      default: () => [10, 30, 50, 100],
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped></style>
