import {
	MessageBox,
	Message
} from 'element-ui'
/**
* @desc 提示气泡框：成功
* @param param  参数名
*/
function successMsg (msg) {
	return message('success', msg)
}

/**
	 * @desc 提示气泡框：失败
	 * @param param  参数名
	 */
function errorMsg (msg) {
	return message('error', msg)
}

/**
 * @desc 提示气泡框
 * @param param  参数名
 */
function message (type, msg, duration = 1500, showClose = true) {
	return Message({
		type: type,
		message: msg,
		duration: duration,
		showClose: showClose,
	})
}

/**
 * @desc 确认框
 * @param message   提示内容
 * @param confirmfun 确定回调函数
 * @param cancelfun 取消回调函数
 * @param title 标题
 */
function massageConfirm (message = "", confirmfun, cancelfun = null, title = "提示",) {
	MessageBox.confirm(message, title, {
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		type: 'warning'
	}).then((e) => {
		if (e == 'confirm') {
			return confirmfun()
		} else {
			return cancelfun()
		}
	}).catch(() => {
	})
}

export default {
	successMsg,
	errorMsg,
	massageConfirm
}