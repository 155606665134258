export default {
  namespaced: true,
  state: {
    fold: false, // 侧边栏是否折叠
    refsh: false, // 刷新页面
  },
  actions: {
  },
  mutations: {
    setRefsh (state) {
      state.refsh = !state.refsh
    },
    setFold (state) {
      state.fold = !state.fold
    }
  }
}