//统一管理咱们项目文件相关的接口
import request from "@/utils/request"
//项目文件相关的请求地址
const API = {
  EXPORT_LIST_URL: "/super/export/list",
  EXPORT_DESTROY_URL: "/super/export/del",
}

//获取下载中心列表
export const exportList = (params) =>
  request.get(API.EXPORT_LIST_URL, { params })

//删除
export const destroy = (data) =>
    request.post(API.EXPORT_DESTROY_URL, data)
