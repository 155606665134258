//统一管理咱们项目用户相关的接口
import request from "@/utils/request"
//项目用户相关的请求地址
const API = {
  LOGIN_URL: "/super/login/password",
  USERINFO_URL: "/super/spread/info"
}

//登录接口
export const reqLogin = (data) =>
  request.post(API.LOGIN_URL, data)
//获取用户信息
export const reqUserInfo = (params) =>
  request.get(API.USERINFO_URL, params)
